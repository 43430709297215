import { model } from '@telekomconsalting/dex-guru-model'
import { BigNumber } from 'bignumber.js'
import debounce from 'lodash.debounce'
import TagManager from 'react-gtm-module'

import { ChartType } from '../components/ActivityOverview/ActivityOverview'
import { PoolActivityFilterNames } from '../components/PoolActivity/PoolActivity.enums'
import { SidebarType } from '../components/Sidebar/sidebarUtils'
import { TradingHistoryFilterNames } from '../components/TransactionHistory/TradingHistory.enums'
import { appGitVersion } from '../config/settings'
import { AmplitudeEvent } from '../constants/amplitudeEvents'
import {
  EmptyObject,
  Filter,
  TokenV3,
  TokenWithApprovalContext,
  Transaction,
  UserWalletCategory,
} from '../model'
import { getMarketDisplayName } from '../utils'
import gtmService from './gtmService'
import { store } from './reduxService'

const ANONYMOUS_KEY = 'Anonymous'

export interface AmplitudeUserContext {
  trader_category?: UserWalletCategory | typeof ANONYMOUS_KEY
  wallet_provider?: string | typeof ANONYMOUS_KEY
  wallet_address?: model.Address | typeof ANONYMOUS_KEY
}

class AmplitudeService {
  // events from https://app.shortcut.com/dexguru/story/13280/update-amplitude-tracking
  // fires Amplitude event without additional props
  public sendEvent = <T extends EmptyObject>(eventName: AmplitudeEvent, data?: T): void => {
    const { user } = store.getState()
    const userContext: AmplitudeUserContext = {
      trader_category: user.walletCategory || ANONYMOUS_KEY,
      wallet_address: user.address || ANONYMOUS_KEY,
      wallet_provider: user.walletProvider || ANONYMOUS_KEY,
    }
    const tagManagerArgs = {
      dataLayer: {
        ...userContext,
        // let actions override the context to avoid async issues with state
        ...(data || {}),
        event: eventName,
        app_version: appGitVersion,
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  public walletConnected = (context: Partial<AmplitudeUserContext>): void => {
    this.sendEvent(AmplitudeEvent.WALLET_CONNECTED, context)
  }

  public walletDisconnected = (context: Partial<AmplitudeUserContext>): void => {
    this.sendEvent(AmplitudeEvent.WALLET_DISCONNECTED, context)
  }

  public interactedWithLeftSidebar = (): void => {
    this.sendEvent(AmplitudeEvent.INTERACTED_WITH_LEFT_COLUMN)
  }

  public interactedWithRightSidebar = (): void => {
    this.sendEvent(AmplitudeEvent.INTERACTED_WITH_RIGHT_COLUMN)
  }

  public interactedWithFormFunc = (): void => {
    this.sendEvent(AmplitudeEvent.INTERACTED_WITH_BUY_SELL_SECTION)
  }

  public marketSelectorOpened = (): void => {
    this.sendEvent(AmplitudeEvent.MARKET_SELECTOR_OPENED)
  }

  public baseCurrencySwitched = (currency: string): void => {
    this.sendEvent(AmplitudeEvent.BASE_CURRENCY_SWITCHED, { base_currency: currency.toUpperCase() })
  }

  public changedTokenPageLayout = (page_layout: string): void => {
    this.sendEvent(AmplitudeEvent.CHANGED_TOKEN_PAGE_LAYOUT, { page_layout })
  }

  public dyorPopupOpened = (tokenId: string, tokenName: string, network: string): void => {
    this.sendEvent(AmplitudeEvent.TOKEN_INFORMATION_VIEWED, {
      token_id: tokenId,
      token_name: tokenName,
      network: network.toUpperCase(),
    })
  }

  public removedFavorite = (tokenId: string, tokenName: string, network: string): void => {
    this.sendEvent(AmplitudeEvent.TOKEN_REMOVED_FROM_FAVORITES, {
      token_id: tokenId,
      token_name: tokenName,
      network: network.toUpperCase(),
    })
  }

  public addedFavorite = (
    tokenId: string,
    tokenName: string,
    network: string,
    postSwap: boolean
  ): void => {
    this.sendEvent(
      postSwap
        ? AmplitudeEvent.FAVORITED_TOKEN_FROM_POST_SWAP
        : AmplitudeEvent.TOKEN_ADDED_TO_FAVORITES,
      {
        token_id: tokenId,
        token_name: tokenName,
        network: network.toUpperCase(),
      }
    )
  }

  public marketSelectorTabViewed = (filter: Filter): void => {
    const filterEventName: Record<typeof filter, AmplitudeEvent> = {
      up: AmplitudeEvent.TOP_VOLUME_TAB_VIEWED,
      favorite: AmplitudeEvent.FAVORITES_TAB_VIEWED,
      'wallet-tab': AmplitudeEvent.WALLET_TAB_VIEWED,
      trending: AmplitudeEvent.TRENDING_TAB_VIEWED,
      gainers: AmplitudeEvent.GAINERS_TAB_VIEWED,
      losers: AmplitudeEvent.LOSERS_TAB_VIEWED,
      recent: AmplitudeEvent.RECENTLY_LISTED_VIEWED,
    }

    this.sendEvent(filterEventName[filter])
  }

  public tokenSelected = (token: TokenV3): void => {
    const marketName = getMarketDisplayName(token)
    const tokenId = token.id
    const network = token.network
    this.sendEvent(AmplitudeEvent.TOKEN_SELECTED_FROM_MARKET_SELECTOR, {
      token_id: tokenId,
      token_name: marketName,
      network: network.toUpperCase(),
    })
  }

  public openedLiquidityV1_1 = (): void => {
    this.sendEvent(AmplitudeEvent.LEFT_SIDEBAR_OPENED_TO_FULL_SCREEN, {})
  }

  public openedTradingHistoryV1_1 = (): void => {
    this.sendEvent(AmplitudeEvent.RIGHT_SIDEBAR_OPENED_TO_FULL_SCREEN, {})
  }

  public adjustedTimeOnExpandedTopTokenHolderChart = (value: string): void => {
    this.sendEvent(AmplitudeEvent.ADJUSTED_TIME_ON_EXPANDED_TOP_TOKEN_HOLDER_CHART, {
      chart_timeperiod: value,
    })
  }

  public adjustedTimeOnExpandedTokenBalanceChart = (value: string): void => {
    this.sendEvent(AmplitudeEvent.ADJUSTED_TIME_ON_EXPANDED_TOKEN_BALANCE_CHART, {
      chart_timeperiod: value,
    })
  }

  public txnCompleted = (
    volumeUSD: BigNumber,
    currentToken: TokenV3,
    quoteToken: TokenV3,
    txn: Transaction
  ): void => {
    if (!txn.hashTxn) {
      return
    }
    const productName = gtmService.getProductName(currentToken, quoteToken)
    const tip = txn.tip || 0
    const tipsUsd = volumeUSD
      .times(tip / 100)
      .dp(2, BigNumber.ROUND_HALF_UP)
      .toFixed() // $ Tips

    this.sendEvent(AmplitudeEvent.TRANSACTION_COMPLETED, {
      revenue: volumeUSD.toFixed(2),
      price: currentToken.priceUSD.toFixed(2),
      quantity: volumeUSD.div(currentToken.priceUSD).toFixed(2),
      token_id: currentToken.id,
      slippage: txn.slippage,
      token_pair: productName,
      tip: tipsUsd,
      gas_price:
        (txn.txnParams.gasPrice && new BigNumber(txn.txnParams.gasPrice).div(10 ** 18).toFixed()) ||
        undefined,
      network: currentToken.network.toUpperCase(),
      network_fee: txn.gasCosts,
      transaction_id: txn.hashTxn,
      transaction_type: txn.type,
    })
  }

  public interactedWithForm = debounce(this.interactedWithFormFunc.bind(this), 1000, {
    maxWait: 1500,
  })

  public poolActivitySidebarChangeFilter({
    currentToken,
    filterName,
  }: {
    currentToken?: TokenWithApprovalContext
    filterName: PoolActivityFilterNames
  }): void {
    if (!currentToken) {
      return
    }
    this.sendEvent(AmplitudeEvent.FILTERED_LIQUIDITY_HISTORY_ON_LIQUIDITY_SIDEBAR, {
      liquidity_history_sidebar_filter: filterName,
      token_id: currentToken?.id,
      token_name: getMarketDisplayName(currentToken),
      network: currentToken?.network,
    })
  }

  public tradingHistoryChangeFilter({
    currentToken,
    filterName,
  }: {
    currentToken?: TokenWithApprovalContext
    filterName: TradingHistoryFilterNames
  }): void {
    amplitudeService.sendEvent(AmplitudeEvent.FILTERED_ORDER_ON_TRADING_SIDEBAR, {
      trading_history_sidebar_filter: filterName.toLowerCase(),
      token_id: currentToken?.id,
      token_name: getMarketDisplayName(currentToken),
      network: currentToken?.network,
    })
  }

  public sendActivityFilterUpdateEvent(type: SidebarType, value: ChartType): void {
    if (type === SidebarType.history) {
      if (value === ChartType.category) {
        amplitudeService.sendEvent(AmplitudeEvent.CHANGED_TRADING_VOLUME_CHART_BY_TRADER_CATEGORY)
      } else if (value === ChartType.market) {
        amplitudeService.sendEvent(AmplitudeEvent.CHANGED_TRADING_VOLUME_CHART_BY_MARKET)
      }
    } else if (type === SidebarType.liquidity) {
      // No events yet
    }
  }
}

const amplitudeService = new AmplitudeService()

export default amplitudeService
