import { ApmBase, init, Transaction } from '@elastic/apm-rum'
import BigNumber from 'bignumber.js'

import { Environment } from '../config/EnvironmentSettings'
import { apmServerUrl, environment } from '../config/settings'
import { APM_TIMEOUT, ERROR_BOUNDARY } from '../constants'
import { Dictionary, QuoteResponse, Source, StatefulTransaction, TxnError } from '../model'
import { Patch } from '../reducers/txn'

export type TxnStatus =
  | 'started'
  | 'updated'
  | 'submitted'
  | 'success'
  | 'error'
  | 'canceled'
  | 'reset'

export type FormType = 'mobile' | 'desktop'

export interface TransactionContextInput {
  quoteResponse?: Partial<QuoteResponse>
  approvalInProgress?: boolean
  txnError?: TxnError
  errorMessage?: string
}

interface TransactionContext {
  quoteEstimatedGas?: number
  quoteFrom?: string
  quoteGas?: string
  quoteGasPrice?: string
  quoteSources?: string
  quoteSellAmount?: BigNumber
  quoteBuyAmount?: BigNumber
  quoteTo?: string
  quotePrice?: string
  approvalInProgress: boolean
  zeroXRequestUrl?: string
  errorMessage?: string
}

let apm: ApmBase | undefined

export const buildTransactionCustomContext = ({
  quoteResponse,
  approvalInProgress,
  txnError,
  errorMessage,
}: TransactionContextInput): TransactionContext => {
  return {
    quoteEstimatedGas: quoteResponse?.estimatedGas,
    quoteFrom: quoteResponse?.from,
    quoteGas: quoteResponse?.gas,
    quoteGasPrice: quoteResponse?.gasPrice,
    quoteSources: quoteResponse?.sources?.map((s: Source) => s.name)?.join(','),
    quoteSellAmount: quoteResponse?.sellAmount,
    quoteBuyAmount: quoteResponse?.buyAmount,
    quoteTo: quoteResponse?.to,
    quotePrice: quoteResponse?.price,
    approvalInProgress: approvalInProgress || false,
    zeroXRequestUrl: txnError?.zeroXRequestUrl,
    errorMessage,
  }
}

export const populateTransactionContextAndLabels = (
  txnStatus: TxnStatus,
  tradeId: string,
  prevState?: StatefulTransaction,
  apmTransaction?: Transaction,
  patch?: Patch,
  formType?: FormType
): Transaction | undefined => {
  const apm = initApm()

  if (!apmTransaction || !apm) {
    return undefined
  }

  const errorMessage = patch?.txnError?.originalError || patch?.txnError?.message || patch?.error
  const errorKind = errorMessage ? patch?.txnError?.kind || 'dex.guru' : ''

  const context: TransactionContextInput = {
    quoteResponse: patch?.quoteResponse || prevState?.quoteResponse || {},
    approvalInProgress: patch?.approvalInProgress || prevState?.approvalInProgress || false,
    txnError: patch?.txnError,
    errorMessage,
  }

  apm.setCustomContext(buildTransactionCustomContext(context))

  const labels = {
    tokenFrom: patch?.fromTokenAddress || prevState?.fromTokenAddress || '',
    tokenTo: patch?.toTokenAddress || prevState?.toTokenAddress || '',
    tokenNetwork: patch?.tokenNetwork || prevState?.tokenNetwork || '',
    hashTxn: patch?.hashTxn || '',
    lastRequest: patch?.lastRequest || '',
    tradeType: prevState?.type || '',
    txnStatus,
    account: patch?.account || prevState?.account || '',
    tradeId,
    formType: formType || 'unknown',
  }

  if (errorMessage) {
    apmTransaction.addLabels({ ...labels, errorKind, errorMessage })
    apm.addLabels({ ...labels, errorKind, errorMessage })
    apm.captureError(errorMessage)
  } else {
    apmTransaction.addLabels(labels)
    apm.addLabels(labels)
  }

  return apmTransaction
}

export const sendApmEvent = (
  transaction: StatefulTransaction | undefined,
  txnStatus: TxnStatus,
  tradeId: string,
  patch?: Patch,
  formType?: FormType
): Transaction | undefined => {
  const apm = initApm()

  if (!patch || !apm) {
    return undefined
  }

  let apmTransaction: Transaction | undefined = apm.startTransaction(transaction?.type, 'Trade')
  apmTransaction = populateTransactionContextAndLabels(
    txnStatus,
    tradeId,
    transaction,
    apmTransaction,
    patch,
    formType
  )

  if (apmTransaction) {
    const span = apmTransaction.startSpan('TradeTxn')
    setTimeout((): void => {
      span?.end()
      apmTransaction?.end()
    }, APM_TIMEOUT)
  }
}

export const sendApmError = (errorKind: string, errorMessage: string): void => {
  const apm = initApm()
  if (apm) {
    apm.addLabels({ errorKind, errorMessage })
    apm.captureError(errorMessage)
  }
}

export const initApm = (): ApmBase | undefined => {
  if (
    !apm &&
    (process.env.REACT_APP_ENVIRONMENT === Environment.production ||
      process.env.REACT_APP_ENVIRONMENT === Environment.test)
  ) {
    apm = init({
      serviceName: 'DEX-GURU Frontend',
      serverUrl: apmServerUrl,
      serviceVersion: process.env.GIT_COMMIT,
      pageLoadTransactionName: '/',
      active: true,
      logLevel: 'warn',
      transactionSampleRate: Number.parseFloat(
        process.env.REACT_APP_RUM_TRANSACTION_SAMPLING_RATE || '1'
      ),
      centralConfig: true,
      distributedTracingOrigins: environment.getAllApiOrigins(),
      environment: process.env.REACT_APP_ENVIRONMENT,
    })

    apm.setInitialPageLoadName(window.location.origin)

    apm.addFilter((payload: Dictionary<unknown>): Dictionary<unknown> | false => {
      if (payload.transactions) {
        const transactions = (payload.transactions as [{ type: string }]).filter(
          (transaction) =>
            transaction.type !== 'http-request' &&
            transaction.type !== 'user-interaction' &&
            transaction.type !== ERROR_BOUNDARY
        )

        return { ...payload, transactions }
      }

      return payload
    })
  }

  if (apm) {
    return apm
  }
}

export default apm
