import { BigNumber } from 'bignumber.js'
import Web3 from 'web3'
import { BlockNumber } from 'web3-core'

import { RPC_ERR } from '../config/settings'
import { TokenV3 } from '../model'
import { getTokenAddress, isNativeTokenForNetwork, retryWrapper } from '../utils'
import { sendApmError } from './apmService'
import { getBalance, getBlockNumber, getErc20Balance } from './chainService'

export const getUserBalance = async ({
  library,
  account,
  walletNetwork,
  tokenFrom,
  blockNumber = 'latest',
}: {
  library: Web3
  account?: string | null
  walletNetwork?: string
  tokenFrom: TokenV3
  blockNumber?: BlockNumber
}): Promise<BigNumber> => {
  if (!account || tokenFrom.network !== walletNetwork) {
    return new BigNumber(0)
  }
  try {
    const tokenFromAddress = getTokenAddress(tokenFrom)
    if (isNativeTokenForNetwork(tokenFrom)) {
      const balance = (await getEthUserBalance(account, library, walletNetwork, blockNumber)).div(
        10 ** 18
      )
      return balance.gt(0) ? balance : new BigNumber(0)
    }
    const balanceFrom = await getErc20UserBalance(
      tokenFromAddress,
      account,
      library,
      walletNetwork,
      blockNumber
    )
    return new BigNumber(balanceFrom).div(10 ** tokenFrom.decimals)
  } catch (err: ReturnType<Error>) {
    console.error(err)
    return new BigNumber(0)
  }
}

export const getErc20UserBalance = async (
  tokenAddress: string,
  account: string,
  library: Web3,
  network: string,
  blockNumber?: BlockNumber
): Promise<BigNumber> => {
  try {
    if (!blockNumber) {
      blockNumber = await getBlockNumber(library, network)
    }

    const task = async (): Promise<string> => {
      return await getErc20Balance(library, network, account, tokenAddress, blockNumber)
    }
    const result = (await retryWrapper(task, 3, true)) as string

    return new BigNumber(result)
  } catch (error) {
    console.error('erc20balance error', error)
    sendApmError('erc20balance-error', `${error}`)
    const err = new Error(RPC_ERR)
    throw err
  }
}

export const getEthUserBalance = async (
  account: string,
  library: Web3,
  network: string,
  blockNumber?: BlockNumber
): Promise<BigNumber> => {
  try {
    if (!blockNumber) {
      blockNumber = await getBlockNumber(library, network)
    }
    const task = async (): Promise<string> => {
      return await getBalance(library, network, account, blockNumber)
    }

    const result = (await retryWrapper(task, 3, true)) as string
    return new BigNumber(result)
  } catch (error) {
    sendApmError('balance-error', `${error}`)
    console.error('balance error', error)
    const err = new Error(RPC_ERR)
    throw err
  }
}
